import React, {Fragment, lazy, Suspense} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./components/Header";
import ReactGA from "react-ga";

import './App.css';
import Footer from "./components/Footer";
import HowItWorks from "./components/HowItWorks";
import WhatWeOffer from "./components/WhatWeOffer";
import HeroCarousel from "./components/HeroCarousel";

const GetQuotationFlow = lazy(() => import('./components/GetQuotationFlow'));
const renderQuotationFlowLoader = () => <p>Loading</p>;

function App() {
    ReactGA.initialize('UA-125314259-5');
    ReactGA.pageview('/');

    return (<Fragment>
            <Header/>
            <HeroCarousel/>
            <HowItWorks/>
            <WhatWeOffer/>
            <Suspense fallback={renderQuotationFlowLoader()}>
                <GetQuotationFlow/>
            </Suspense>
            <Footer/>
        </Fragment>
    );
}

export default App;
