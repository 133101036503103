import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "./HeroCarousel.css";
import Hero1 from "../assets/images/hero-1.jpg";
import Hero2 from "../assets/images/hero-2.jpg";
import Hero3 from "../assets/images/hero-3.jpg";
import Hero4 from "../assets/images/hero-4.jpg";
import Hero5 from "../assets/images/hero-5.jpg";

const HeroCarousel = () => {
    return <Carousel>
        <Carousel.Item>
            <img
                className="d-block w-100"
                src={Hero1}
                alt="First slide"
            />
        </Carousel.Item>
        <Carousel.Item>
            <img
                className="d-block w-100"
                src={Hero2}
                alt="Second slide"
            />
        </Carousel.Item>
        <Carousel.Item>
            <img
                className="d-block w-100"
                src={Hero3}
                alt="Third slide"
            />
        </Carousel.Item>
        <Carousel.Item>
            <img
                className="d-block w-100"
                src={Hero4}
                alt="Fourth slide"
            />
        </Carousel.Item>
        <Carousel.Item>
            <img
                className="d-block w-100"
                src={Hero5}
                alt="Fifth slide"
            />
        </Carousel.Item>
    </Carousel>;
};

export default HeroCarousel;