import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faTools, faShippingFast} from "@fortawesome/free-solid-svg-icons";
import {faCreditCard} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DevicesImg from "../assets/images/we-offer-bg.png";
import BgImg from "../assets/images/we-offer-gradient-bg.png";
import "./WhatWeOffer.css";

library.add(faTools, faShippingFast, faCreditCard);

const sectionStyle = {
    background: `url(${BgImg}) center center repeat #d8d9da`
};

const WhatWeOffer = () => {
    return <div id="what-we-offer" className="section-container what-we-offer-section" style={sectionStyle}>
        <Container>
            <div className="section-title">
                <h2>What we offer</h2>
            </div>
            <Row>
                <Col>
                    <img src={DevicesImg} className="img-fluid" alt="bg" />
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <div className="item-wrapper text-center">
                        <div className="icon mb-5"><FontAwesomeIcon icon={['far', 'credit-card']} size="lg"/></div>
                        <div className="title mb-4">$0.00<br />Deductible</div>
                        <div className="subtitle">Members NEVER pay deductible, when client makes a claim they only pay a one time technician fee of $20.00.</div>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="item-wrapper text-center">
                        <div className="icon mb-5"><FontAwesomeIcon icon="tools" size="lg"/></div>
                        <div className="title mb-4">Three free<br />fixes per year</div>
                        <div className="subtitle">Members receive three free fixes per year. Each additional fix will have a 50% discount applied.</div>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="item-wrapper text-center">
                        <div className="icon mb-5"><FontAwesomeIcon icon="shipping-fast" size="lg"/></div>
                        <div className="title mb-4">We come<br />to you</div>
                        <div className="subtitle">Members select when and where and we do the rest.</div>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
};

export default WhatWeOffer;