import React from 'react';
import {Container, Navbar, Nav, Row, Col} from 'react-bootstrap';
import CustomHashLink from "./CustomHashLink";
import logo from '../assets/images/logo.png';
import './Header.css'

const Header = () => {
    return <header className="main-header fixed-top">
        <div className="upper-header-wrapper d-none d-lg-block d-xl-block">
            <Container>
                <Row>
                    <Col md={5} className="text-left">NOW OFFERING PROTECTION PLAN</Col>
                    <Col md={2} className="text-center"><CustomHashLink to="/#get-quote" href="/#get-quote" smooth>GET A QUOTE</CustomHashLink></Col>
                    <Col md={5} className="text-right"><a href="https://www.123cellphonesolutions.com/">123CELLPHONESOLUTIONS.COM</a></Col>
                </Row>

            </Container>
        </div>
        <div className="navbar-header-wrapper">
            <Container>
                <Navbar expand="xl" className="custom-navbar" collapseOnSelect={true}>
                    <Navbar.Brand href="/">
                        <img src={logo} height={60} alt="logo"/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto">
                            <Nav.Item>
                                <CustomHashLink to="/#how-it-works" href="/#how-it-works" smooth>How it works</CustomHashLink>
                            </Nav.Item>
                            <Nav.Item>
                                <CustomHashLink to="/#what-we-offer" href="/#what-we-offer" smooth>What we offer</CustomHashLink>
                            </Nav.Item>
                            <Nav.Item>
                                <CustomHashLink to="/#get-quote" href="/#get-quote" smooth className="custom-button button-blue">Get a quote</CustomHashLink>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link href="https://client.123cellphonesolutions.com/" target="_blank" className="custom-button button-red">Make a claim</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </Container>
        </div>

    </header>;
};

export default Header;