import React from 'react';
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faMobileAlt, faThumbsUp} from "@fortawesome/free-solid-svg-icons";
import {faHandshake} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Row from "react-bootstrap/Row";
import CustomHashLink from "./CustomHashLink";
import "./HowItWorks.css";

library.add(faMobileAlt, faHandshake, faThumbsUp);

const HowItWorks = () => {
    return <div id="how-it-works" className="section-container how-it-works-section">
        <Container>
            <div className="section-title">
                <h2>How it works</h2>
            </div>
            <Row>
                <Col md={4}>
                    <div className="item-wrapper text-center first mb-4">
                        <div className="icon mb-4"><FontAwesomeIcon icon="mobile-alt" size="lg"/></div>
                        <div className="title mb-4">Select your device</div>
                        <div className="subtitle">Add one or more devices to check prices. Add more than one device and receive a discount on your second device*.</div>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="item-wrapper text-center second mb-4">
                        <div className="icon mb-4"><FontAwesomeIcon icon={['far', 'handshake']} size="lg"/></div>
                        <div className="title mb-4">Get a quote</div>
                        <div className="subtitle">Get an automatic membership quote. No need to submit any info to get a quote.</div>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="item-wrapper text-center third mb-4">
                        <div className="icon mb-4"><FontAwesomeIcon icon="thumbs-up" size="lg"/></div>
                        <div className="title mb-4">Make an appointment</div>
                        <div className="subtitle">Let us know when and where and we come to you to do the membership. No prepayment or credit card is required to schedule an appointment.</div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="highlight-text text-center mt-3 pl-3 pr-3">
                    NO CONTRACT, NO HIRES FEE , NO SMALL LETTERS MEMBERS CAN CANCEL ANY TIME.
                </Col>
            </Row>
            <Row>
                <Col className="text-center mt-5 mb-5">
                    <CustomHashLink to="/#get-quote" smooth className="custom-button button-blue button-outline text-center">Get a quote</CustomHashLink>
                </Col>
            </Row>
        </Container>
    </div>
};

export default HowItWorks;