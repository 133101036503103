import React from 'react';
import {Container} from 'react-bootstrap';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import logo from '../assets/images/logo-white.png';
import './Footer.css';

library.add(fab);

const Footer = () => {
    return <footer className="footer-wrapper">
        <Container className="footer-container text-center">
            <div><img src={logo} alt="logo" height={60}/></div>
            <div className="social-icons">
                <div className="social-icon">
                    <a href="https://www.facebook.com/123cellphonesolutions/">
                        <FontAwesomeIcon icon={['fab', 'facebook-f']}/>&nbsp; Facebook
                    </a>
                </div>
                <div className="social-icon">
                    <a href="https://www.instagram.com/123cellphonesolutions/">
                        <FontAwesomeIcon icon={['fab', 'instagram']}/>&nbsp; Instagram
                    </a>
                </div>
            </div>
            <div>{(new Date().getFullYear())} &copy; Copyrights. All Rights Reserved.</div>
        </Container>
    </footer>
};

export default Footer;